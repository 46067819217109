import React from "react";
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import GuideBanner from '../../components/GuideDetails/GuideBanner/GuideBanner'
import GuideDetails from '../../components/GuideDetails/GuideDetails'
import FeaturedProperties from '../../components/GuideDetails/FeaturedProperties/FeaturedProperties'




const GuideDetail = () => {
    const [state, setState] = React.useState({
        showMenu: false
    })

    const handlerClick = () => { 
        setState({ ...state, showMenu: !state.showMenu })
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }

    }
    return (
        <>
            <div className={state.showMenu ? "wrapper open-search-block" : "wrapper"}>
                <Header showMenu={state.showMenu} handlerClick={handlerClick} />
                <GuideBanner />
                <GuideDetails />
                <FeaturedProperties />
                <Footer />
            </div>
        </>
    )
}

export default GuideDetail;